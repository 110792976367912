export const taskListColumns = [
    {
        title: "#",
        dataIndex: "id",
        slots: { customRender: "serialNumber" },
        width: 70,
        align: 'center',
        fixed: 'left',
    },
    {
        title: "任务名称/编码",
        dataIndex: "taskBasisTitle",
        slots: { customRender: "taskBasisTitle" },
        fixed: 'left',
        width: 200,
    },
    {
        title: "项目名称/编码",
        dataIndex: "prjName",
        slots: { customRender: "prjName" },
        fixed: 'left',
        width: 200,
    },
    {
        title: "任务执行区域",
        dataIndex: "taskDimension",
        slots: { customRender: "taskDimension" },
        width: 100,
    },
    // {
    //     title: "终端名称/编码",
    //     dataIndex: "terminalName",
    //     slots: { customRender: "terminalName" },
    //     fixed: 'left',
    //     width:170,
    // },
    // {
    //     title: "地址",
    //     dataIndex: "address",
    //     slots: { customRender: "province" },
    //     fixed: 'left',
    //     align:'center',
    //     width: 180,
    // },
    // {
    //   title: "省份",
    //   dataIndex: "province",
    //   slots: { customRender: "province" },
    //   width:60,
    // },
    // {
    //   title: "城市",
    //   dataIndex: "city",
    //   slots: { customRender: "city" },
    //   width:60,
    // },
    // {
    //   title: "区县",
    //   dataIndex: "area",
    //   slots: { customRender: "area" },
    //   width:80,
    // },
    {
        title: "是否报名审核",
        dataIndex: "receiveAudit",
        slots: { customRender: "receiveAudit" },
        width:100,
    },
    {
        title: "任务类型",
        dataIndex: "typeName",
        slots: { customRender: "typeName" },
        width: 100,
    },
    {
        title: "执行结束时间",
        dataIndex: "endTime",
        slots: { customRender: "endTime" },
        width: 100,
    },

    // {
    //     title: "发布人",
    //     dataIndex: "publishName",
    //     width:100,
    // },
    // {
    //     title: "发布时间",
    //     dataIndex: "publishTime",
    //     slots: { customRender: "publishTime" },
    //     width:100,
    // },
    {
        title: "审核数量",
        dataIndex: "count",
        slots: { customRender: "auditCount" },
        width: 100,
        fixed: "right",
    },
    {
        title: "操作",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
        width: 100,
    },
]
export const taskAuditColumns = [
    //     {
    //     title: "",
    //     dataIndex: "id",
    //     slots: { customRender: "serialNumber" },
    //     width:30,
    //     fixed: 'left',
    // },
    {
        title: "触点姓名",
        dataIndex: "userName",
        slots: { customRender: "userName" },
        fixed: 'left',
        width: 150,
    },
    // {
    //     title: "联系电话",
    //     dataIndex: "prjName",
    //     slots: { customRender: "prjName" },
    //     fixed: 'left',
    //     width:150,
    // },
    {
        title: "领取时间",
        dataIndex: "receiveTime",
        slots: { customRender: "receiveTime" },
        width: 150,
    },
    {
        title: "审核人/审核时间",
        dataIndex: "auditUserName",
        slots: { customRender: "auditUserName" },
        fixed: 'left',
        width: 150,
    },

    {
        title: "审核状态",
        dataIndex: "auditStatus",
        fixed: 'left',
        slots: { customRender: "auditStatus" },
        width: 150,
    },
    {
        title: "操作",
        slots: { customRender: "handle" },
        width: 100,
    },

]