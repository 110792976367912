
import { message } from "ant-design-vue";
import { taskAuditColumns } from "@/api/fixedData.js";
import { auditDetail, audit } from "../../api/taskAuditApi.js";
import moment from "moment";
import { logout } from "@/utils/requestFunction";
import { SyncOutlined, SearchOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    SyncOutlined,
    SearchOutlined,
  },
  data() {
    return {
      visible: false,
      formState: {
        custName: "",
        checkName: "",
        taskType: undefined,
        rangePicker: [],
        auditPicker: [],
      },
      //计划人数
      todoNumber: 0,
      // 已报名人数
      finishedNumber: 0,
      // 已通过人数
      passNumber: 0,
      // 待审核人数
      auditNumber: 0,
      //任务id
      taskDetailId: 0,
      taskTypeOptions: [
        { value: "0", label: "待审核" },
        { value: "2", label: "已通过" },
        { value: "1", label: "已驳回" },
      ],
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      keepCustomData: [],
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      //table的样式
      batchStyle: "middle",
      //table的布局
      scroll: { x: "100%" },
      // 详情弹窗
      detailVisiable: false,
      // 审核弹窗
      auditVisiable: false,
      auditName: "",
      auditTime: "",
      // 确认审核内容
      auditMsg: "",
      auditFlg: 0,
      // 任务审核详情数据
      taskDetail: {},
      comfirmLoading: false,
    };
  },
  methods: {
    showModal(task) {
      (this as any).formState = {
        custName: "",
        checkName: "",
        taskType: undefined,
        rangePicker: [],
        auditPicker: [],
      };
      (this as any).todoNumber = task.planNum;
      if ((this as any).todoNumber >= 100000) {
        (this as any).todoNumber = "--";
      }
      console.log(task);
      (this as any).taskDetailId = task.taskDetailsId;
      auditDetail({
        taskDetailsId: task.taskDetailsId,
        pageSize: (this as any).pageSize,
        pageNumber: (this as any).pageNum,
      }).then((res: any) => {
        console.log(res.data.data);
        (this as any).data = res.data.data;

        if ((this as any).data.length <= 0 || !(this as any).data) {
          //  (this as any).todoNumber = 0;
          // 已报名人数
          (this as any).finishedNumber = 0;
          // 已通过人数
          (this as any).passNumber = 0;
          // 待审核人数
          (this as any).auditNumber = 0;
        } else {
          let detail = res.data.data[0];
          (this as any).finishedNumber = detail.receiveNum;
          (this as any).passNumber = detail.auditNum;
          (this as any).auditNumber = detail.unAuditNum;
        }
        (this as any).visible = true;
        (this as any).total = res.data.total;

        // if()
      });

      //   this.$refs.formRef.resetFields();
    },
    getAudit() {
      if ((this as any).formState.rangePicker.length > 0) {
        (this as any).formState.rangePicker[0] =
          (this as any).formatDate((this as any).formState.rangePicker[0]) +
          " " +
          "00:00:00";
      }
      if ((this as any).formState.rangePicker.length > 1) {
        (this as any).formState.rangePicker[1] =
          this.formatDate((this as any).formState.rangePicker[1]) +
          " " +
          "23:59:59";
      }
      if ((this as any).formState.auditPicker.length > 0) {
        (this as any).formState.auditPicker[0] =
          (this as any).formatDate((this as any).formState.auditPicker[0]) +
          " " +
          "00:00:00";
      }
      if ((this as any).formState.auditPicker.length > 1) {
        (this as any).formState.auditPicker[1] =
          (this as any).formatDate((this as any).formState.auditPicker[1]) +
          " " +
          "23:59:59";
      }
      auditDetail({
        taskDetailsId: (this as any).taskDetailId,
        userNameOrCode: (this as any).formState.custName,
        auditUserNameOrCode: (this as any).formState.checkName,
        auditStatus: (this as any).formState.taskType,
        startReceiveTime: (this as any).formState.rangePicker[0],
        endReceiveTime: (this as any).formState.rangePicker[1],
        startAuditTime: (this as any).formState.auditPicker[0],
        endAuditTime: (this as any).formState.auditPicker[1],
        pageSize: (this as any).pageSize,
        pageNumber: (this as any).pageNum,
      }).then((res: any) => {
        console.log(res.data.data);
        (this as any).data = res.data.data;
        let detail = res.data.data[0];
        if (detail) {
          (this as any).finishedNumber = detail.receiveNum;
          (this as any).passNumber = detail.auditNum;
          (this as any).auditNumber = detail.unAuditNum;
        }
        // (this as any).todoNumber = task.planNum;
        (this as any).total = res.data.total;
        (this as any).visible = true;
      });
    },
    onSearch() {
      this.getAudit();
    },
    onReset() {
      (this as any).formState.custName = "";
      (this as any).formState.checkName = "";
      (this as any).formState.taskType = "";
      (this as any).formState.rangePicker = [];
      (this as any).formState.auditPicker = [];
      this.getAudit();
    },
    //更换每页显示数量
    onShowSizeChange(current, pageSize) {
      (this as any).pageNum = 1;
      (this as any).pageCurrent = 1;
      (this as any).pageSize = pageSize;
      this.getAudit();
    },
    alertAudit(record) {
      (this as any).detailVisiable = true;
      (this as any).auditName = record.auditUserName;
      (this as any).auditTime = record.auditTime;
    },
    passAudit(record) {
      (this as any).auditVisiable = true;
      console.log(record);
      (this as any).auditFlg = 2;
      (this as any).taskDetail = record;
      (this as any).auditMsg =
        "审核通过后，该触点将会收到任务领取成功通知，请再次确认是否通过？";
    },
    rejectAudit(record) {
      (this as any).auditVisiable = true;
      console.log(record);
      (this as any).auditFlg = 1;
      (this as any).taskDetail = record;
      (this as any).auditMsg =
        "审核驳回后，该触点将会收到任务领取失败通知，请再次确认是否驳回？";
    },
    confirmAudit() {
      (this as any).confirmLoading = true;
      audit({
        taskDetailsId: (this as any).taskDetail.taskDetailsId,
        userCode: (this as any).taskDetail.userCode,
        auditReason: "",
        taskStatus: (this as any).auditFlg,
      }).then((res: any) => {
        if (res.data.success) {
          message.success("审核成功！");
          (this as any).auditVisiable = false;
          (this as any).confirmLoading = false;
          this.getAudit();
          (this as any).$emit("auditFlg", (this as any).auditVisiable);
        }
      });
    },
    cancelAudit() {
      (this as any).auditVisiable = false;
      // (this as any).confirmLoading = true;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    (this as any).columns = taskAuditColumns;
  },
};
