
import request from '../utils/request.js';
//获取省份 城市  地区
export function getProvince() {
    return request().get('/api/base/v1/areaselect/province/find')
}
export function getCity() {
    return request(Code).get(`/api/base/v1/areaselect/city/find?provinceCode=${Code}`)
}
export function getArea() {
    return request(Code).get(`/api/base/v1/areaselect/area/find?cityCode=${Code}`)
}
export function auditList(params){
    return request().post('/api/taskhall/v1/auditApp/receive/list', {
        ...params,
    })
}
export function auditDetail(params){
    return request().post('/api/taskhall/v1/auditApp/receive/info', {
        ...params,
    })
}
export function audit(params){
    return request().post('/api/taskhall/v1/auditApp/receive/confirm', {
        ...params,
    })
}