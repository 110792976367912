<template>
  <div>
    <div class="screen">
      <div class="formBox">
        <!-- :label-col="{ style: { width: '30%' } }" -->
        <a-form :model="formState" :label-col="{ style: { width: '90px' } }">
          <a-row class="rowBox">
            <a-col :span="4" class="formCol">
              <div>任务名称 / 编码</div>
              <a-input
                v-model:value="formState.taskCodeOrName"
                placeholder="请输入..."
                allowClear
                class="input"
              />
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="formCol">
              <div>项目名称/编码</div>
              <a-input
                v-model:value="formState.projectCodeOrName"
                placeholder="请输入..."
                allowClear
                class="input"
              />
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="formCol">
              <div>任务执行区域</div>
              <a-select
                v-model:value="formState.publishType"
                placeholder="请选择..."
                :options="taskPublish"
                allowClear
              >
              </a-select>
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="formCol">
              <div>是否报名审核</div>
              <a-select
                placeholder="请选择..."
                style="width: 100%"
                v-model:value="receiveAudit"
                :options="receiveAuditOptions"
                optionFilterProp="label"
                allowClear
              >
              </a-select>
              <!-- <div>终端名称/编码</div>
              <a-input
                v-model:value="formState.terminalCodeOrName"
                placeholder="请输入..."
              /> -->
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4">
              <div>任务类型</div>
              <a-select
                v-model:value="formState.taskType"
                :options="taskTypeOptions"
                placeholder="请选择..."
                allowClear
              >
              </a-select>

              <!-- <div>省市区</div>
              <a-cascader
                style="width: 100%"
                v-model:value="regionValue"
                :options="options"
                :loadData="loadData"
                placeholder="请选择..."
                change-on-select
                @blur="blurRegionValue"
              /> -->
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <div>执行结束时间</div>
              <a-range-picker
                :disabledDate="disabledDate"
                v-model:value="formState.rangePicker"
                @calendarChange="onCalendarChange"
                @openChange="onOpenChange"
                allowClear
              >
              </a-range-picker>
              <!-- <a-col :span="11">
                    <a-date-picker
                      v-model:value="formState.startDateTime"
                      placeholder="请选择"
                    />
                  </a-col>
                  <a-col :span="2">
                    <div style="text-align: center">-</div>
                  </a-col>
                  <a-col :span="11">
                    <a-date-picker
                      v-model:value="formState.endDateTime"
                      placeholder="请选择"
                    />
                  </a-col> -->
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="formCol">
              <!-- <div>发布人</div>
              <a-input
                v-model:value="formState.publisher"
                placeholder="请输入..."
              /> -->
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="formCol"> </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4"></a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4" class="searchBoxfather">
              <div class="searchBox">
                <a-button type="primary" @click="onSubmit"
                  ><SearchOutlined
                    style="font-size: 14px; position: relative; top: 1px"
                  />
                  搜索</a-button
                >
                <a-button
                  @click="onReset"
                  style="
                    margin-left: 10px;
                    border-color: #008bcf;
                    color: #008bcf;
                  "
                >
                  <SyncOutlined
                    style="font-size: 14px; position: relative; top: 1px"
                  />重置</a-button
                >
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div
      style="
        margin-top: 20px;
        background: #fff;
        padding: 0 30px 20px;
        border-radius: 4px;
      "
    >
      <div style="height: 10px"></div>
      <div
        style="
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          box-shadow: inset 0px -1px 0px 0px rgba(232, 232, 232, 1);
        "
      >
        <div class="moduleTitle">任务报名审核</div>
        <div style="display: flex">
          <a-popover>
            <template #content>
              <span>刷新</span>
            </template>
            <div class="SettingOutlinedBox" @click="search">
              <ReloadOutlined />
            </div>
          </a-popover>
          <a-popover trigger="click" placement="bottom">
            <template #content>
              <div class="batch">
                <div
                  :class="this.batchStyle == 'default' ? 'active' : ''"
                  @click="batchStyle = 'default'"
                >
                  宽松
                </div>
                <div
                  :class="this.batchStyle == 'middle' ? 'active' : ''"
                  @click="batchStyle = 'middle'"
                >
                  默认
                </div>
                <div
                  :class="this.batchStyle == 'small' ? 'active' : ''"
                  @click="batchStyle = 'small'"
                >
                  紧凑
                </div>
              </div>
            </template>
            <a-popover>
              <template #content>
                <span>密度</span>
              </template>
              <div class="SettingOutlinedBox"><ColumnHeightOutlined /></div>
            </a-popover>
          </a-popover>
          <a-popover title="列展示" trigger="click" placement="bottomRight">
            <template #content>
              <div class="a-popoverContent">
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">任务名称/编码</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">项目名称/编码</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">任务执行区域</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">是否报名审核</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">任务类型</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">执行结束时间</span>
                </div>
                <div>
                  <a-checkbox checked disabled />
                  <span style="margin-left: 5px">审核数量</span>
                </div>
                <!-- <div v-for="item in customData" :key="item.key">
                  <label>
                    <a-checkbox
                      v-model:checked="item.checked"
                      @change="inputChange(item)"
                    />
                    <span style="margin-left: 5px">{{ item.title }}</span>
                  </label>
                </div> -->
              </div>
            </template>
            <a-popover placement="top">
              <template #content>
                <span>列设置</span>
              </template>
              <div class="SettingOutlinedBox" title="列设置">
                <SettingOutlined />
              </div>
            </a-popover>
          </a-popover>
        </div>
      </div>
      <div>
        <!-- :style="{ height: tableHeight, overflow: 'auto' }" -->
        <a-table
          rowKey="id"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :scroll="scroll"
          :loading="loading"
          :size="batchStyle"
        >
          <template #serialNumber="{ index }">
            <span class="serialNumber">
              {{ index + 1 }}
            </span>
          </template>
          <template #taskBasisTitle="{ record }">
            <div class="terminalNameContainer">
              <div class="iconbox">
                <a href="javascript:"><TagsFilled /></a>
              </div>
              <div>
                <div class="terminalNameBox longBox">
                  <a
                    href="javascript:"
                    class="terminalName"
                    :title="record.taskInfoTitle"
                    @click="toPreview(record.taskInfoId)"
                  >
                    {{ record.taskInfoTitle }}</a
                  >
                </div>
                <div
                  class="terminalNameBox longBox"
                  style="color: #ccc"
                  :title="record.terminalCode"
                >
                  {{ record.taskInfoCode }}
                </div>
              </div>
            </div>
          </template>
          <template #prjName="{ record }">
            <div class="terminalNameContainer">
              <div>
                <div class="terminalNameBox longBox">
                  {{ record.prjName }}
                </div>
                <div
                  class="terminalNameBox longBox"
                  style="color: #ccc"
                  :title="record.terminalCode"
                >
                  {{ record.prjCode }}
                </div>
              </div>
            </div>
          </template>
          <template #receiveAudit="{ record }">
            {{ record.receiveAudit ? "是" : "否" }}
          </template>
          <template #terminalName="{ record }">
            <div class="terminalNameContainer">
              <div>
                <div class="terminalNameBox longBox">
                  {{ record.terminalName }}
                </div>
                <div
                  class="terminalNameBox longBox"
                  style="color: #ccc"
                  :title="record.terminalCode"
                >
                  {{ record.terminalCode }}
                </div>
              </div>
            </div>
          </template>
          <template #province="{ record }">
            <div>
              <div>
                {{ record.province }}
                <span v-if="record.city">/</span>
                {{ record.city }}
                <span v-if="record.area">/</span>
                {{ record.area }}
                <!-- <span v-if="record.town">/{{ record.town }}</span> -->
              </div>
              <div class="terminalNameBox" :title="record.address">
                <span>
                  {{ record.address }}
                </span>
              </div>
            </div>
          </template>
          <template #endTime="{ record }">
            <span v-if="record.endTime != null">{{
              formatDate(record.endTime) + " " + "23:59:59"
            }}</span>
            <span v-else-if="record.endTime == null">--</span>
          </template>
          <template #publishTime="{ record }">
            <span v-if="record.publishTime != null">
              {{ record.publishTime }}
            </span>
            <span v-else-if="record.publishTime == null">--</span>
          </template>
          <template #taskDimension="{ record }">
            <span v-if="record.taskDimension == 1">不限区域</span>
            <span v-else-if="record.taskDimension == 2">终端任务</span>
            <span v-else-if="record.taskDimension == 3">区域任务</span>
            <span v-else-if="record.taskDimension == null">--</span>
          </template>
          <template #auditCount="{ record }">
            待审核：<span v-if="record.unAuditNum != null" style="color: red">{{
              record.unAuditNum
            }}</span>
            <span v-else-if="record.unAuditNum == null" style="color: red"
              >0</span
            >
            <br />
            已通过：<span
              v-if="record.unAuditNum != null"
              style="color: green"
              >{{ record.auditNum }}</span
            >
            <span v-else-if="record.unAuditNum == null" style="color: green"
              >0</span
            >
          </template>

          <template #handle="{ record }">
            <!-- {{ record }} -->
            <div class="iconbox" @click="taskDetails(record)">
              <a href="javascript:" style="color: #008bcf"
                >审核
                <!-- <DownOutlined/> -->
              </a>
            </div>
          </template>
        </a-table>
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 20px"
      >
        <div>
          共<span style="margin: 0 10px">{{ total }}</span
          >条数据
        </div>
        <a-pagination
          show-size-changer
          show-quick-jumper
          v-model:current="pageCurrent"
          v-model:pageSize="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          :page-size-options="['10', '20', '50', '100']"
        />
      </div>
    </div>

    <AuditModal ref="AuditModal" @auditFlg="getAudit"></AuditModal>
  </div>
</template>

<script >
import axios from "axios";
import moment from "moment";
import { getProvince, auditList } from "../../api/taskAuditApi.js";
import AuditModal from "../../components/task/AuditModal.vue";
import {
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  SyncOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import { taskListColumns } from "@/api/fixedData.js";
export default {
  components: {
    SettingOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    FileAddFilled,
    StopOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    TagsFilled,
    SyncOutlined,
    SearchOutlined,
    AuditModal,
    DownOutlined,
  },
  data() {
    return {
      formState: {
        taskCodeOrName: "",
        taskType: undefined,
        publishType: undefined,
        projectCodeOrName: "",
        terminalCodeOrName: "",
        startDateTime: "",
        endDateTime: "",
        publisher: "",
        rangePicker: [],
      },
      cityCurrent: 0,
      options: [],
      loading: true,
      regionValue: [],
      keepCustomData: [],
      taskTypeOptions: [{ value: "活动推广", label: "活动推广" }],
      taskPublish: [
        {
          value: 1,
          label: "不限区域",
        },
        // {
        //   value: 2,
        //   label: "终端任务",
        // },
        // {
        //   value: 3,
        //   label: "区域任务",
        // },
      ],
      receiveAudit: null,
      receiveAuditOptions: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      //table的样式
      batchStyle: "middle",
      //table的布局
      scroll: { x: "100%" },
      columns: [],
      data: [],
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: sessionStorage.getItem("auditPageNum") || 1,
      // 每页数据数量
      pageSize: sessionStorage.getItem("auditPageSize") || 10,
      pageNum: sessionStorage.getItem("auditPageNum") || 1,
      //总数据量
      total: 0,
    };
  },
  methods: {
    taskDetails(record) {
      console.log(this.$refs.AuditModal);
      if (this.$refs.AuditModal) {
        this.$refs.AuditModal.showModal(record);
      }
    },
    search() {
      this.getAuditList();
    },
    //省市区懒加载
    loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (this.cityCurrent === 0) {
        axios
          .get(
            `/api/base/v1/areaselect/city/find?provinceCode=${targetOption.code}`
          )
          .then((res) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item.code,
                label: item.name,
                isLeaf: false,
                level: item.level,
              };
            });

            targetOption.children = newOptions;
            this.options = [...this.options];
            keepOption = [...newOptions];
            this.cityCurrent = this.cityCurrent + 1;
          });
      }
      if (this.cityCurrent === 1) {
        let flag = this.options.filter((i) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/base/v1/areaselect/area/find?cityCode=${targetOption.code}`
          )
          .then((res) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item.code,
                label: item.name,
                level: item.level,
                isLeaf: true,
              };
            });
            targetOption.children = newOptions;
            let areaFlag = keepOption.filter((i) => {
              return i.level == targetOption.level;
            }).length;

            if (areaFlag) {
              this.cityCurrent = 1;
              this.loadData(selectedOptions);
              return;
            } else {
              this.options = [...this.options];
              this.cityCurrent = 1;
            }
          });
      }
    },
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
    },
    //跳转预览页面
    toPreview(id) {
      this.$router.push(`/taskHall/details/${id}`);
    },
    //复选结果
    onSelectChange(selectedRowKeys) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //左侧的多选列禁用筛选
    getCheckboxProps(record) {
      if (!record.status) {
        return { disabled: true };
      }
    },
    //弹出Popover 进行筛选
    handelColumns() {
      this.columns = [...taskListColumns, ...this.keepCustomData];
    },
    getAuditList() {
      this.loading = true;
      let area = "";
      if (this.regionValue != null && this.regionValue.length != 0) {
        area = this.regionValue[this.regionValue.length - 1];
      }
      if (this.formState.rangePicker.length > 0) {
        this.formState.rangePicker[0] =
          this.formatDate(this.formState.rangePicker[0]) + " " + "00:00:00";
      }
      if (this.formState.rangePicker.length > 1) {
        this.formState.rangePicker[1] =
          this.formatDate(this.formState.rangePicker[1]) + " " + "23:59:59";
      }
      auditList({
        receiveAudit: this.receiveAudit,
        codeOrTitle: this.formState.taskCodeOrName,
        taskType: this.formState.taskType,
        prjNameOrCode: this.formState.projectCodeOrName,
        terminalNameOrCode: this.formState.terminalCodeOrName,
        publishNameOrCode: this.formState.publisher,
        startEndTime: this.formState.rangePicker[0],
        endEndTime: this.formState.rangePicker[1],
        taskDimension: this.formState.publishType,
        area: area,
        pageSize: this.pageSize,
        pageNumber: this.pageNum,
      })
        .then((res) => {
          this.total = res.data.total;
          this.data = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAudit(auditVisiable) {
      console.log(897, auditVisiable);
      if (!auditVisiable) {
        this.getAuditList();
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //更换每页显示数量
    onShowSizeChange(current, pageSize) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      sessionStorage.setItem("auditPageSize", pageSize + "");
      this.getAuditList();
    },
    onReset() {
      this.receiveAudit = null;
      this.formState.taskCodeOrName = "";
      this.formState.taskType = undefined;
      this.formState.projectCodeOrName = "";
      this.formState.terminalCodeOrName = "";
      this.formState.publisher = "";
      this.formState.startDateTime = "";
      this.formState.endDateTime = "";
      this.formState.publishType = undefined;
      this.regionValue = [];
      this.formState.rangePicker = [];
      this.getAuditList();
    },
    onSubmit() {
      this.getAuditList();
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.receiveAudit = null;
        this.taskInfoTitleOrCode = "";
        this.prjNameOrCode = "";
        this.terminalNameOrCode = "";
        this.regionValue = [];
        this.taskDimension = null;
        this.taskStatus = null;
        this.typeCode = null;
        this.rangePicker = [];
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent;
      sessionStorage.setItem("auditPageNum", this.pageCurrent + "");
      this.getAuditList();
    },
  },
  mounted() {
    this.getAuditList();
    //获取省份
    getProvince().then((res) => {
      let newOptions = res.data.data.map((item) => {
        return {
          ...item,
          value: item.code,
          label: item.name,
          isLeaf: false,
        };
      });
      this.options = newOptions;
    });
    this.columns = taskListColumns;
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (max-width: 1350px) {
//   .searchBox {
//     width: 168px;
//     float: right;
//   }
// }
.searchBox {
  min-width: 200px;
}
.searchBoxfather {
  position: relative;
  .searchBox {
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
.screen {
  font-size: 12px;
  color: #8c8c8c;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  background: #fff;
  padding: 20px 40px 8px 40px;
  font-size: 12px;
  div {
    margin-bottom: 5px;
  }
  .rowBox {
    margin-bottom: 15px;
  }
}
.moduleTitle {
  height: 20px;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  font-weight: 600;
}
.terminalNameContainer {
  display: flex;
  .iconbox {
    margin-right: 5px;
    font-size: 13px;
  }
}
.terminalNameBox {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .terminalName {
    text-decoration: underline;
  }
}
.longBox.terminalNameBox {
  width: 170px;
}
.SettingOutlinedBox {
  position: relative;
  // left: 43%;
  bottom: 3px;
  height: 14px;
  padding: 0;
  border-color: #fff;
  border: none;
  border-radius: 50%;
  color: #8c8c8c;
  // line-height: 32px;
  margin-left: 30px;
  font-size: 14px;
  cursor: pointer;
  // span {
  //   position: relative;
  //   top: 3px;
  // }
}
.SettingOutlinedBox:hover {
  color: #008bcf;
}
.batch {
  width: 80px;
  cursor: pointer;
  div {
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  div.active {
    background: #008bcf;
    color: #fff;
  }
  div:hover:not(.active) {
    background: #ccc;
    color: #fff;
  }
}
</style>

<style lang="scss" >
.ant-pagination-options-size-changer.ant-select {
  width: 90px;
  text-align: center;
}
.screen .ant-select-selector {
  height: 30px !important;
}
.screen .input .ant-input {
  height: 20px !important;
}
.screen .ant-calendar-range-picker-input {
  height: 20px !important;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #8c8c8c;
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
</style>